import React from 'react';
import { LanguageConsumer } from './LanguageContext';

const withLanguage = (WrappedComponent) => (props) => (
	<LanguageConsumer>
		{value => <WrappedComponent {...props} languageContext={value} />}
	</LanguageConsumer>
);


export default withLanguage;
