import React from 'react'
import './SectionDivider.css';

const SectionDivider = ({ className = '', leftComponent, rightComponent, noGap = false, hideMobile, inverted = false }) => {
	return (
		<div className={`sectionDivider-container ${inverted ? 'inverted' : ''} ${className} ${noGap ? '' : 'gap'}`}>
			<div className={`section left  ${hideMobile === 'left' ? 'hide' : ''}`}>{leftComponent}</div>
			<div className={`section right ${hideMobile === 'right' ? 'hide' : ''}`}>{rightComponent}</div>

		</div>
	)
}

export default SectionDivider