import React from 'react'
import './LinkBtnImg.css';
import Link from './Link'
import TextType from '../Text/TextType/TextType'

const LinkBtnImg = ({ className='', children, ...rest }) => {
	return <Link {...rest} className={`link-btn-img ${className}`}>
		<img className='btn-background' alt='button background' src="/Images/btn_img.png" />
		<TextType className='btn-text' header1>{children}</TextType>
	</Link>

}

export default LinkBtnImg