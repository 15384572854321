import React from 'react'
import {
	ContentFluid,
	CardImageContainer,
	Banner,
	PageLayout,
	CardText,
	TextType
} from '../../../Components';
import { withLanguage } from '../../../Context';
import './ParceirosPage.css'

const ParceirosPage = ({ languageContext }) => {
	const { getValue, languagesConfigs } = languageContext;
	const getTranlation = (key) => {
		const { parceiros } = languagesConfigs;
		return getValue(parceiros, key);
	}
	return (
		<PageLayout className='parceiros-page'>
			<Banner imgSrc="/Images/Parceiros/Banner.png" />
			<section className="space-top">
				<ContentFluid>
					<TextType header1>{getTranlation("header")}</TextType>
					<TextType header2 secondType>{getTranlation("sub-header")}</TextType>
					<div className='space-top30'>
						<TextType header2 secondType>{getTranlation("headerLogosInstitucionais")}</TextType>
						<div class="partners-logos">
							<img alt='ICA logo' src='/Images/Parceiros/Ica.png' />
							<img alt='ECDC logo' src='/Images/Parceiros/ECDC.png' />
							<img alt='Escola de Montanha logo' src='/Images/Parceiros/EscolaMontanha.png' />
							<img alt='GEM logo' src='/Images/Parceiros/GEM.png' />
							<img alt='NEL logo' src='/Images/Parceiros/NEL.png' />
							<img alt='ROKOMONDO logo' src='/Images/Parceiros/ROKOMONDO.png' />
						</div>
					</div>
					<div>
						<TextType header2 secondType>{getTranlation("headerLogosEmpresariais")}</TextType>
						<div class="partners-logos">
							<img alt='NOMAD logo' src='/Images/Parceiros/NOMAD.png' />
							<img alt='Lobo Tours logo' src='/Images/Parceiros/Lobo.png' />
							<img alt='Tobogã adventure tours logo' src='/Images/Parceiros/toboga.png' />
						</div>
					</div>
				</ContentFluid>
			</section>
		</PageLayout>
	)
}

export default withLanguage(ParceirosPage);