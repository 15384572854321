import CardImage from './CardImage'
import './CardImageContainer.css';

const CardImageContainer = ({
	cards = [
		{ imgSrc: "/Images/Home/img_home_destaque1_A.png" },
		{ imgSrc: "/Images/Home/img_home_destaque1_B.png" },
		{ imgSrc: "/Images/Home/img_home_destaque1_C.png" },
		{ imgSrc: "/Images/Home/img_home_destaque1_D.png" }
	]
}) => {
	return (
		<div className='cards-image_container'>
			{cards.map((card, index) => <CardImage key={`CardImage${index}`} {...card} />)}
		</div>
	)
}

export default CardImageContainer