import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Header, RouterScrollTop } from './Components'
import { LanguageContextProvider } from './Context';
import {
	HomePage,
	WhatIsItPage,
	GeresBiosferaPage,
	// ComissaoOrganizadoraPage,
	ForAllPage,
	TransportePage,
	RegiaoPage,
	ContatosPage,
	AlojamentoPage,
	ParceirosPage,
	CompeticaoPage,
	ProgramaPage,
	AvailableSoonPage,
	EscolasPage,
	CanyonsPage
} from './Pages';
import './App.css';


function App() {
	return (
		<LanguageContextProvider>
			<BrowserRouter>
				<RouterScrollTop>
					<Header />
					<div className='app-main-content'>
						<Routes>
							<Route path="/" element={<HomePage />} />
							<Route path="/OQueE" element={<WhatIsItPage />} />
							<Route path="/GeresReservaBiosfera" element={<GeresBiosferaPage />} />
							<Route path="/ComissaoOrganizadora" element={<AvailableSoonPage />} />
							<Route path="/Transporte" element={<TransportePage />} />
							<Route path="/Regiao" element={<RegiaoPage />} />
							<Route path="/Contatos" element={<ContatosPage />} />
							<Route path="/Alojamento" element={<AlojamentoPage />} />
							<Route path="/Competicao" element={<CompeticaoPage />} />
							<Route path="/Escolas" element={<EscolasPage />} />
							<Route path="/4All" element={<ForAllPage />} />
							<Route path="/Programa" element={<ProgramaPage />} />
							<Route path="/Parceiros" element={<ParceirosPage />} />
							<Route path="/Canyons" element={<AvailableSoonPage />} />
							<Route path="/CanyonsTeste" element={<CanyonsPage />} />
						</Routes>
					</div>
				</RouterScrollTop>
			</BrowserRouter>
		</LanguageContextProvider>
	);
}

export default App;
