import React from 'react'
import './Footer.css';
const Footer = () => {
  return (
	<div className='footer space-top'>

	</div>
  )
}

export default Footer