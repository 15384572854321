import {
	TextType,
	CardIcon,
	ContentFluid,
	PageLayout,
	SectionDivider,
	CardImageContainer,
	Banner,
	LinkBtn,
	Link,
	LinkBtnImg
} from '../../Components'
import { withLanguage } from '../../Context'
import './HomePage.css'

const HomePage = ({ languageContext }) => {

	const { getHomeValue } = languageContext;
	return (
		<PageLayout className="home-page">
			<Banner fullHeight imgSrc='/Images/Home/img_home.png'>
				<ContentFluid>
					<div className='home-page_banner-text-wrapper'>						
						<div className='home-page_banner-text'>
							<div className='banner_canyoning'>canyoning</div>
							<div className='banner_summit'>Summit</div>
							<div className='banner_location'>Gerês-Xurés</div>
							<div className='banner_date'>2023</div>
						</div>
						<LinkBtnImg to="https://forms.gle/Zz9KGeGR2D9gUuix5" external className='registration-btn'>{getHomeValue("registration")}</LinkBtnImg>
					</div>
				</ContentFluid>
			</Banner>
			<section className="home-page_images space-top">
				<ContentFluid>
					<TextType header1 secondType>2023</TextType>
					<TextType header1 extraBold>Canyoning Summit</TextType>
					<TextType header1>{getHomeValue("header")}</TextType>
					<div className='space-top20'>
						<CardImageContainer cards={[
							{ imgSrc: "/Images/Home/img_home_destaque1_A.png" },
							{ imgSrc: "/Images/Home/img_home_destaque1_B.png" },
							{ imgSrc: "/Images/Home/img_home_destaque1_C.png" },
							{ imgSrc: "/Images/Home/img_home_destaque1_D.png" },
						]} />
					</div>
				</ContentFluid>
			</section>
			<section className="what space-top">
				<ContentFluid>
					<SectionDivider
						hideMobile="left"
						leftComponent={
							<img alt='' className='what-image' src="/Images/Home/img_home_destaque_2.png" />
						}
						rightComponent={
							<div className='what-conatiner_description'>
								<div>
									<TextType className="section2_description">{getHomeValue("section2_description")}</TextType>
									<LinkBtn to="/OQueE">{getHomeValue("section2_buttonLabel")}</LinkBtn>
								</div>
								<div className='what-conatiner_cards'>
									<SectionDivider
										leftComponent={
											<>
												<CardIcon imgSrc="/Images/Home/ICON _ NATUREZA.png" text={
													<Link to="/GeresReservaBiosfera">
														<span>{getHomeValue("section2_cardDescription1_1")}</span>
														<br />
														<span>{getHomeValue("section2_cardDescription1_2")}</span>

													</Link>
												} />
												<CardIcon imgSrc="/Images/Home/ICON _ TRANSPORTE.png" text={
													<Link to="/Transporte">
														{getHomeValue("section2_cardDescription3")}
													</Link>} />
											</>
										}
										rightComponent={
											<>
												<CardIcon imgSrc="/Images/Home/ICON _ ORGANIZAÇAO.png" text={
													<Link to="/ComissaoOrganizadora">
														{getHomeValue("section2_cardDescription2")}
													</Link>} />
												<CardIcon imgSrc="/Images/Home/ICON _ REGIAO.png" text={
													<Link to="/Regiao">
														{getHomeValue("section2_cardDescription4")}
													</Link>} />
											</>
										}
									/>
								</div>

							</div>
						}
					>

					</SectionDivider>
				</ContentFluid>
			</section >
		</PageLayout >

	)
}

export default withLanguage(HomePage);