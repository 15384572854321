import React from 'react'
import { Footer } from '..'

const PageLayout = ({ className, children, noFooter = false }) => {
	return (
		<div className={className}>
			{children}
			{!noFooter && <Footer />}
		</div>
	)
}

export default PageLayout