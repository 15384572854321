import './IconButton.css';

const IconButton = ({ disabled, onClick, className, ...rest }) => {
	const _onClick = () => {
		if (!disabled) {
			onClick();
		}
	}

	return (
		<button className={`icon-button_container ${className || ''}`} onClick={_onClick} {...rest} />
	)
}

export default IconButton