import React from 'react'
import { ContentFluid, Banner, PageLayout, SectionDivider, CardImageContainer, TextType } from '../../Components'
import { withLanguage } from '../../Context';
import './CanyonsPage.css'


const Row = ({ info }) => {
	return <tr>
		<td className='cell-label'><TextType>{info.label}:</TextType></td>
		<td className='cell-value'><TextType textAlign='right'>{info.value}</TextType></td>
	</tr>
}

const CanyonsPage = ({ languageContext }) => {
	const { getValue, getObject, getValueFormVar, languagesConfigs } = languageContext;
	const { o_que_e } = languagesConfigs;
	const getTranlation = (key) => {
		return getValue(o_que_e, key);
	}


	const options = {
		description: [
			{
				label: 'Aproximação',
				value: '1 min.'
			},
			{
				label: 'Percurso',
				value: '2:30h'
			},
			{
				label: 'Regresso',
				value: '20 min.'
			},
			{
				label: 'Altitude início',
				value: '390 m'
			},
			{
				label: 'Altitude fim',
				value: '182 m'
			},
			{
				label: 'Extensão',
				value: '1650 m'
			},
			{
				label: 'Rapéis',
				value: '8'
			},
			{
				label: 'Cascata mais alta',
				value: '16m'
			},
			{
				label: 'Corda mínima',
				value: '40m'
			},
			{
				label: 'Dificuldade vertical',
				value: ''
			},
			{
				label: 'Dificuldade aquática',
				value: ''
			},
			{
				label: 'Dificuldade de saída',
				value: ''
			},
			{
				label: 'Ligação carro',
				value: '5 min.'
			}
		],
		coordenadas: [
			{
				label: 'Coordenadas',
				value: ''
			},
			{
				label: 'Início',
				value: ''
			},
			{
				label: 'Fim',
				value: ''
			},
			{
				label: 'Saída1',
				value: ''
			},
			{
				label: 'Saída2',
				value: ''
			},
			{
				label: 'P1',
				value: ''
			},
			{
				label: 'P2',
				value: ''
			},
			{
				label: 'D1',
				value: ''
			}
		]
	};


	return (
		<PageLayout className='canyons-page'>
			<Banner imgSrc="/Images/Canyons/Banner.png" />
			<ContentFluid>
				<section className="main-content">
					<SectionDivider leftComponent={
						<div className='canyon-specs_container'>
							<div className='canyon-specs_box'>
								<table>
									{
										options.description.map(item => (
											<Row info={item} />
										))
									}
									<tr>
										<td colSpan={2}>
											<hr />
										</td>
									</tr>
									{
										options.coordenadas.map(item => (
											<Row info={item} />
										))
									}


								</table>
							</div>
							<div className='croqui-images-container'>
								<img src='/Images/Canyons/Croqui.png' />
							</div>

						</div>
					}
						rightComponent={
							<div>
								<TextType header1>Ribeira de carcerelha</TextType>
								<TextType header2 secondType className="space-top30">Caracterização:</TextType>
								<TextType>Rio de uma beleza ímpar com lagoas de água critalina, sendo de constituíção rochosa granítica em toda a sua extensão. Com uma orientação virado a sul, permite devido à ausência de vegetação, uma descida sempre com boa exposição solar.</TextType>
								<TextType header2 secondType className="space-top30">Check Point do Canyon</TextType>
								<TextType>Junto ao pilar da pont. Verificar antes de entrar</TextType>
								<TextType header2 secondType className="space-top30">Descida</TextType>
								<TextType>Aconcelhada para praticantes experientes, passagem da fenda (R5) algo complicada com caudal forte.</TextType>
								<TextType header2 secondType className="space-top30">Saída</TextType>
								<TextType>Junto da ponte, mesmo local de entrada.</TextType>
								<div className='croqui-images-container space-top30'>
									<img src='/Images/Canyons/Croqui.png' />
								</div>
								<div className='map-image-container space-top30'>
									<img src='/Images/Canyons/map.png' />
								</div>
							</div>
						}
					/>
				</section>
				<section className="space-top30">
					<CardImageContainer />
				</section>
			</ContentFluid>


		</PageLayout >
	)
}

export default withLanguage(CanyonsPage);