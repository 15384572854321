import React from 'react'
import {
	ContentFluid,
	PageLayout,
	TextType,
	CardImageContainer
} from '../../Components';
import { withLanguage } from '../../Context';
import './CompeticaoPage.css'

const CompeticaoPage = ({ languageContext }) => {
	const { getValue, languagesConfigs, getSelectedLanguagues } = languageContext;
	const getTranlation = (key) => {
		const { competicao } = languagesConfigs;
		return getValue(competicao, key);
	}

	const imgRegulationInfo = getSelectedLanguagues() === "pt" ? {
		src: "/Images/regulamento_pt.png",
		alt: "Link regulamento"
	}
		: {
			src: "/Images/regulamento_en.png",
			alt: "Link regulation"
		};

	const imgInscriptionInfo = getSelectedLanguagues() === "pt" ? {
		src: "/Images/inscricao_pt.png",
		alt: "Link inscrição"
	}
		: {
			src: "/Images/inscricao_en.png",
			alt: "Link inscription"
		};


	return (
		<PageLayout className='competicao-page'>
			<section className='section-1'>
				<div className='text-wrapper'>
					<ContentFluid>
						<div className='text-content'>
							<TextType className="main-header" header1>{getTranlation("header")}</TextType>
							<TextType className="text-description space-top30">{getTranlation("description")}</TextType>
							<div className='text-prizes_container space-top10'>
								<div className='text-prizes'>
									<TextType className="prizes-header" header1 secondType>{getTranlation("prizes_header")}</TextType>
									<TextType className="places">{getTranlation("1_lugar")}: €600</TextType>
									<TextType className="places">{getTranlation("2_lugar")}: €300</TextType>
									<TextType className="places">{getTranlation("3_lugar")}: €150</TextType>

									<div className='description space-top30'>
										<TextType className="places">{getTranlation("Equipa")}:</TextType><TextType className="places" secondType>{getTranlation("Equipa_desc")}</TextType>
									</div>
									<div className='description'>
										<TextType className="places">{getTranlation("Inscription")}:</TextType><TextType className="places" secondType>{getTranlation("Inscription_desc")}</TextType>
									</div>
									<div className='description'>
										<TextType className="places">{getTranlation("location")}:</TextType><TextType className="places" secondType textAlign='left'>{getTranlation("location_desc")}</TextType>
									</div>
									<div className='btn-info space-top20'>

										<a target="_blank" href={`/docs/RegulamentoCompeticao_${getSelectedLanguagues()}.pdf`} rel="noreferrer">
											<img alt={imgRegulationInfo.alt} src={imgRegulationInfo.src} />
										</a>
										<a target="_blank" href='https://docs.google.com/forms/d/e/1FAIpQLSfof3Jzr0YZXqsOLNBwhkhODeMYnC0GhPj9i80tu8LNZA6Uvw/viewform' rel="noreferrer">
										<img alt={imgInscriptionInfo.alt} src={imgInscriptionInfo.src} />
										</a>
									</div>
								</div>
								<div className='banner_samll' >
									<img src="/Images/Competicao/banner_1.png" />
								</div>
							</div>

						</div>
					</ContentFluid>

				</div>
				<div className='banner' >
					<img alt='banner_image' src="/Images/Competicao/banner_1.png" />
				</div>
			</section >
			<section className="parceiros-page_images space-top">
				<ContentFluid>

					<div className='space-top20'>
						<CardImageContainer cards={[
							{ imgSrc: "/Images/Competicao/img_destaque1.png" },
							{ imgSrc: "/Images/Competicao/img_destaque2.png" },
							{ imgSrc: "/Images/Competicao/img_destaque3.png" },
							{ imgSrc: "/Images/Home/img_home_destaque1_C.png" },
						]} />
					</div>
				</ContentFluid>
			</section>



		</PageLayout >
	)
}

export default withLanguage(CompeticaoPage);