import React from 'react'
import {
	ContentFluid,
	Banner,
	PageLayout,
	CardIcon,
	TextType,
	GoogleMaps,
	SectionDivider,
	CardText,
	Icons,
	Link
} from '../../Components';
import { withLanguage } from '../../Context';
import './ContatosPage.css'

const ContatosPage = ({ languageContext }) => {
	const { getValue, languagesConfigs } = languageContext;
	const getTranlation = (key) => {
		const { contatos } = languagesConfigs;
		return getValue(contatos, key);
	}
	return (
		<PageLayout className='contatos-page'>
			<Banner heightAuto fullHeight>
				<ContentFluid>
					<div className='banner-content'>
						<SectionDivider
							inverted
							className="data-content"
							noGap
							leftComponent={
								<GoogleMaps />
							}
							rightComponent={
								<>
									<img className='logo' alt='logo' src='/Images/Logotipo_cores.png' />
									<div className="address space-top20">
										<CardText title={getTranlation("address")} >
											<TextType textAlign='left'>Rua do Ribeiro, n. 145 - Lourido</TextType>
											<TextType textAlign='left'>4980-313 Entre Ambos-os-Rios, Ponte da Barca</TextType>
											<TextType textAlign='left'>Portugal</TextType>
										</CardText>
									</div>

									<SectionDivider
										className="space-top20"
										noGap
										leftComponent={
											<CardText title={getTranlation("emailAddress")}>canyoning.summit@gmail.com</CardText>
										}
										rightComponent={
											<CardText className='socials' title={getTranlation("socials")}>
												<Link to="https://instagram.com/canyoning.summit" external >
													<Icons type="instagram" />
												</Link>
												<Link to="https://www.facebook.com/canyoning.summit" external>
													<Icons type="facebook" />
												</Link>
											</CardText>
										}
									/>
								</>
							}
						/>
					</div>
				</ContentFluid>

			</Banner>
			<ContentFluid>
				<section className="transportes space-top">
					<TextType header1 secondType>{getTranlation("directions")}</TextType>
					<TextType header2>{getTranlation("description")}</TextType>
					<div className='cards'>
						<CardIcon icon="airport" text={<Link to="/Transporte">{getTranlation("airport")}</Link>} />
						<CardIcon icon="train" text={<Link to="/Transporte">{getTranlation("train")}</Link>} />
						<CardIcon icon="bus" text={<Link to="/Transporte">{getTranlation("bus")}</Link>} />
						<CardIcon icon="car" text={<Link to="/Transporte">{getTranlation("car")}</Link>} />
					</div>
				</section>
			</ContentFluid>

		</PageLayout>
	)
}

export default withLanguage(ContatosPage);