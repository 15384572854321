import { useState, useEffect } from 'react';
import Link from "../Link/Link"
import './Header.css';
import ContentFluid from '../Content/ContentFluid'
import { withLanguage } from '../../Context'
import { IconButton, Icons } from '../'
import useWindowSize from '../../Hooks/useWindowSize';

const Header = ({ languageContext }) => {
	const [showMenu, setShowMenu] = useState(false);

	const [width] = useWindowSize();

	useEffect(() => {
		if (width >= 900) {
			setShowMenu(false);
		}
	}, [width])

	return (
		<nav className="header-wrapper">
			<ContentFluid>
				<div className="header-container">
					<div className="header-logo">
						<IconButton className="btn-menu_open" onClick={() => { setShowMenu(true) }}>
							<Icons type="bars" size="30px" />
						</IconButton>
						<Link className='logo-link' onClick={() => setShowMenu(false)} to="/">
							<img className="logo" alt="logo" src="/Images/Header/logotipo.png" />
						</Link>
					</div>
					<div className="right-menus">
						<div className={`header-container_Menus ${showMenu ? 'show' : ''}`}>
							<div className='header-container_Menus_header'>
								<img className="logo" alt="logo" src="/Images/Header/logotipo.png" />
								<IconButton className="btn-menu_close" onClick={() => { setShowMenu(false) }}>
									<Icons type="close" size="30px" />
								</IconButton>
							</div>
							<ul className='menus_container'>
								<li className={`first-level_link`}>
									<Link to="#">
										{languageContext.getHeaderValue("cannyoning_summit")}
									</Link>
									<div id="sub-menu-canyonig-summit" className="sub-menu" >
										<ul className='sub-menu_list'>
											<li className="second-level_link">
												<Link onClick={() => setShowMenu(false)} to="/OQueE">
													{languageContext.getHeaderValue("o_que_e")}
												</Link>
											</li>
											<li className="second-level_link">
												<Link onClick={() => setShowMenu(false)} to="/GeresReservaBiosfera">
													{languageContext.getHeaderValue("geres_biosfera")}
												</Link>
											</li>
											<li className="second-level_link">
												<Link onClick={() => setShowMenu(false)} to="/ComissaoOrganizadora">
													{languageContext.getHeaderValue("comissao_organizadora")}
												</Link>
											</li>
											<li className="second-level_link">
												<Link onClick={() => setShowMenu(false)} to="/Parceiros">
													{languageContext.getHeaderValue("parceiros")}
												</Link>
											</li>
											<li className="second-level_link">
												<Link onClick={() => setShowMenu(false)} to="/Transporte">
													{languageContext.getHeaderValue("transporte")}
												</Link>
											</li>
											<li className="second-level_link">
												<Link onClick={() => setShowMenu(false)} to="/Regiao">
													{languageContext.getHeaderValue("regiao")}
												</Link>
											</li>
											<li className="second-level_link">
												<Link onClick={() => setShowMenu(false)} to="/Canyons">
													{languageContext.getHeaderValue("canyons")}
												</Link>
											</li>
											<li className="second-level_link">
												<Link onClick={() => setShowMenu(false)} to="/Alojamento">{languageContext.getHeaderValue("alojamento")}</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="first-level_link">
									<Link onClick={() => setShowMenu(false)} to="/Programa">{languageContext.getHeaderValue("programa")}</Link>
								</li>
								<li className="first-level_link">
									<Link onClick={() => setShowMenu(false)} to="/Competicao">{languageContext.getHeaderValue("competicao")}</Link>
								</li>
								<li className="first-level_link">
									<Link onClick={() => setShowMenu(false)} to="/Escolas">{languageContext.getHeaderValue("escolas")}</Link>
								</li>
								<li className="first-level_link">
									<Link onClick={() => setShowMenu(false)} to="/4All">{languageContext.getHeaderValue("4all")}</Link>
								</li>
								<li className="first-level_link">
									<Link onClick={() => setShowMenu(false)} to="/Contatos">{languageContext.getHeaderValue("contatos")}</Link>
								</li>
							</ul>
						</div>
						<div className="languages">
							{languageContext.getLanguagues().map(language =>
								<img key={`language_${language.value}`} style={{ cursor: 'pointer' }} alt="flag portugal" src={language.src} onClick={() => { languageContext.selectLanguage(language.value) }} />
							)}
						</div>
					</div>
				</div>
			</ContentFluid>

		</nav >
	)
}

export default withLanguage(Header)