import './CardImage.css';

const CardImage = ({ imgSrc, title }) => {
	return (
		<div className='card-image_container'>
			<img alt="" src={imgSrc} />
			{title && <div className='card_image_text'>{title}</div>}
		</div>
	)
}

export default CardImage