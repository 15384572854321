import React, { useState, useEffect } from 'react'
import { LanguageProvider } from './LanguageContext';

const LanguageContextProvider = ({ children }) => {
	const [selectedLanguagues, setSelectedLanguages] = useState(null);
	const [languagesConfigs, setLanguagesConfigs] = useState(null);

	useEffect(() => {
		fetch("/Languages/Languages.json").then((res) => res.json()).then(res => {
			setLanguagesConfigs(res);

			const languageLocalStorage = localStorage.getItem("language"); 
			changeLanguage(languageLocalStorage || res.languages[0].value);
		}).catch(console.error)
	},[]);


	const changeLanguage = (lang)=> {
		localStorage.setItem("language", lang); 
		setSelectedLanguages(lang);
	}


	const getValueFormVar = (variavel) => {
		if (!variavel) {
			return "";
		}
		if (typeof variavel === 'string')
			return variavel
		return variavel[selectedLanguagues] || variavel.pt;
	}

	const getValue = (objectContext, key) => getValueFormVar(objectContext[key]);

	const getHeaderValue = (key) => getValue(languagesConfigs.header, key);
	const getHomeValue = (key) => getValue(languagesConfigs.home, key);

	const getLanguagues = () => languagesConfigs.languages;

	const getObject = (objectContext, key)=> {
		return objectContext[key]
	}
	
	return (
		<LanguageProvider value={{
			getHeaderValue,
			getLanguagues,
			getHomeValue,
			languagesConfigs,
			selectLanguage: changeLanguage,
			getValue,
			getValueFormVar,
			getObject,
			getSelectedLanguagues: ()=> selectedLanguagues
		}}>
			{languagesConfigs && children}
		</LanguageProvider>
	)
}

export default LanguageContextProvider;