import React from 'react'
import { ContentFluid, CardImageContainer, PageLayout, SectionDivider, TextType } from '../../../Components';
import { withLanguage } from '../../../Context';
import './ComissaoOrganizadoraPage.css'

const ComissaoOrganizadoraPage = ({ languageContext }) => {
	const { getValue, languagesConfigs } = languageContext;
	const getTranlation = (key) => {
		const { Comissao_organizadora } = languagesConfigs;
		return getValue(Comissao_organizadora, key);
	}

	return (
		<PageLayout className='oraganizacao-page'>
			<ContentFluid>
				<SectionDivider
					leftComponent={
						<div>
							<img className='img' alt='' src='/Images/IMG_banner_home.png' />
							<div className='space-top10'>
								<TextType>{getTranlation("section1_description")}</TextType>
							</div>
						</div>
					}
					rightComponent={
						<div className='oraganizacao-content'>
							<img className='img' alt='logo' src='/Images/Logotipo_cores.png' />
							<div >
								<div className="content-text">
									<TextType header2 secondType>{getTranlation("section1_header")}</TextType>
									<TextType header1>{getTranlation("section1_subHeader")}</TextType>
								</div>
								<img className='img space-top10' alt='' src='/Images/IMG_banner.png' />
							</div>
						</div>
					}
				/>

			</ContentFluid>
			<section className="space-top">
				<ContentFluid>
					<CardImageContainer />
				</ContentFluid>
			</section>
		</PageLayout>
	)
}

export default withLanguage(ComissaoOrganizadoraPage);