import React from 'react'
import { ContentFluid, CardImageContainer, PageLayout, LinkBtn, SectionDivider, TextType, Banner } from '../../../Components';
import { withLanguage } from '../../../Context';
import './GeresBiosferaPage.css'

const GeresBiosferaPage = ({ languageContext }) => {
	const { getValue, languagesConfigs } = languageContext;
	const getTranlation = (key) => {
		const { geres_biosfera } = languagesConfigs;
		return getValue(geres_biosfera, key);
	}
	return (
		<PageLayout className='geresBiosfera-page'>
			<Banner imgSrc="/Images/GeresBiosfera/banner_biosfera.png" />
			<ContentFluid>
				<section className="main-content">

					<SectionDivider
						leftComponent={
							<img className='img' alt='logo' src='/Images/mapa pt_ MENUcanyoning _ submenu _biosfera.png' />
						}
						rightComponent={
							<>
								<TextType header1>{getTranlation("section1_header")}</TextType>
								<TextType header2 secondType>{getTranlation("section1_subHeader_1")}</TextType>
								<TextType>{getTranlation("section1_description_1")}</TextType>
								<div className='space-top10'>
									<TextType>{getTranlation("section1_description_2")}</TextType>
								</div>
								<div className='space-top10'>
									<TextType>{getTranlation("section1_description_2")}</TextType>
								</div>
								<div className='space-top10'>
									<TextType>
										{getTranlation("section1_From_start")}
										<a target="_blank" href={getTranlation("section1_From_link")} rel="noreferrer">
											{getTranlation("section1_From_link_label")}
										</a>
										{getTranlation("section1_From_end")}
									</TextType>
								</div>
								<div className='link-more space-top20'>
									<LinkBtn external to={getTranlation("section1_more_link")}>{getTranlation("section1_more_label")}</LinkBtn>
								</div>

							</>
						}
					/>
				</section>
			</ContentFluid>
			<section className="space-top">
				<ContentFluid>
					<CardImageContainer cards={[
						{
							imgSrc: '/Images/GeresBiosfera/destaque_1.png'
						},
						{
							imgSrc: '/Images/GeresBiosfera/destaque_2.png'
						},
						{
							imgSrc: '/Images/GeresBiosfera/destaque_3_1.png'
						},
						{
							imgSrc: '/Images/GeresBiosfera/destaque_4.png'
						}
					]} />
				</ContentFluid>
			</section>

		</PageLayout>
	)
}

export default withLanguage(GeresBiosferaPage);