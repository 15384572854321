import React from 'react'
import { Link as LinkRouter } from 'react-router-dom';
import './Link.css';

const Link = ({ className = '', to = "#", children, external = false, onClick }) => {
	return external ?
		<a target='_blank' href={to} className={`link ${className}`} rel="noreferrer" onClick>{children}</a>
		: <LinkRouter className={`link ${className}`} to={to} onClick={onClick}>{children}</LinkRouter>

}

export default Link