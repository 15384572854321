import React from 'react'
import CardIcon from '../CardIcon/CardIcon';
import TextType from '../Text/TextType/TextType';
import { withLanguage } from '../../Context';

const TranportInfo = ({ languageContext }) => {
	const { getValue, languagesConfigs } = languageContext;
	const getTranlation = (key) => {
		const { transportInfo } = languagesConfigs;
		return getValue(transportInfo, key);
	}

	return (
		<div className='tranport-info_wrap'>
			<CardIcon width='80px' icon="airport" text={(
				<>
					<TextType bold>{getTranlation("airport_Porto_label")}</TextType>
					<TextType>{getTranlation("airport_Porto_description")}</TextType>
				</>

			)} />
			<CardIcon width='80px' icon="airport" text={(
				<>
					<TextType bold>{getTranlation("airport_Vigo_label")}</TextType>
					<TextType>{getTranlation("airport_Vigo_description")}</TextType>
				</>

			)} />
			<CardIcon width='80px' icon="train" text={(
				<>
					<TextType bold>{getTranlation("train_ourense_label")}</TextType>
					<TextType>{getTranlation("train_ourense_description")}</TextType>
				</>

			)} />
			<CardIcon width='80px' icon="bus" text={(
				<>
					<TextType bold>{getTranlation("bus_porto_label")}</TextType>
					<TextType>{getTranlation("bus_porto_description")}</TextType>
				</>

			)} />
			<CardIcon width='80px' icon="car" text={
				<TextType bold>{getTranlation("car_label")}</TextType>
			}
			/>
		</div>
	)
}

export default withLanguage(TranportInfo)