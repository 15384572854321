import React from 'react'
import TextType from '../TextType/TextType'
import './CardText.css'

const CardText = ({className='', title, children }) => {
	return (
		<div className={`card-text_container ${className}`}>
			<TextType className="card-text_header" bold>{title}</TextType>
			<div className='card-text_text'>
				<TextType>{children}</TextType>
			</div>
		</div>
	)
}

export default CardText