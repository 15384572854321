import React, { useState } from 'react'
import { ContentFluid, PageLayout, PlaceAndDays, TextType, Banner, List, Icons, LinkBtnImg } from '../../Components';
import { withLanguage } from '../../Context';
import './ProgramaPage.css'

const GroupContent = withLanguage(({ item, expanded, onExpandedChange, languageContext, registrationLabel }) => {

	const { getValue, getObject, getValueFormVar } = languageContext;
	const getTranlation = (key) => {
		return getValue(item, key);
	}

	const title = getTranlation("title");
	const time = getTranlation("time");
	const description = getTranlation("description");
	const descriptions = getObject(item, "descriptions") || [];
	const options = getObject(item, 'options')?.map(opt => getValueFormVar(opt))
	const logos = getObject(item, 'logos') || [];
	const hasExpander = item.children && item.children.length > 0;
	const registerLink = getTranlation('registerLink');

	return (
		<div className='group-content' onClick={() => onExpandedChange()}>
			<div className={`group-header ${hasExpander ? 'expander' : ''}`}>
				<div>
					<div className='label'>
						{title}
						{(hasExpander) && <span className='expander-icon'>
							{expanded ? <Icons type="collapse" size="16px" /> : <Icons type="expand" size="16px" />}
						</span>
						}
					</div>
					{descriptions.length > 0 && <LinkBtnImg to={registerLink} external className='registration-btn'>{registrationLabel}</LinkBtnImg>
					}
				</div>
				{time && <div className='horario'>
					{time}
				</div>
				}
			</div>
			<div className='group-description'>
				{description}
				<List options={options} />

				{descriptions.map(desc => (
					<div className='group-description_row'>
						<div className='label'>
							<div>
								<TextType bold>{getValueFormVar(desc.description)}</TextType>
								<TextType>{getValueFormVar(desc.person)}</TextType>
							</div>
							<List options={getObject(desc, 'options')?.map(opt => getValueFormVar(opt))} />
						</div>
						<div className='horario'>
							{getValueFormVar(desc.time)}
						</div>
					</div>
				))
				}
				<div className=' logos'>
					{logos.map(({ className = '', ...rest }) => (
						<div>
							<div className={`logo ${className}`}>
								<img {...rest} />
							</div>
						</div>
					))}
				</div >
			</div>
		</div>
	);
});

const Group = ({
	level = 1,
	item,
	registrationLabel
}) => {
	const [expanded, setExpanded] = useState(false);
	return (
		<div className={`group group${level}`}>
			<GroupContent item={item} expanded={expanded} onExpandedChange={() => setExpanded(!expanded)} registrationLabel={registrationLabel} />
			{expanded && item.children && item.children.map((_item, index) => {
				return <Group key={`group$level${index}`} item={_item} level={level + 1} registrationLabel={registrationLabel} />
			})}
		</div>
	)
}

const ProgramaPage = ({ languageContext }) => {
	const { getValue, getObject, languagesConfigs } = languageContext;
	const { program } = languagesConfigs;
	const getTranlation = (key) => {
		return getValue(program, key);
	}

	const programs = getObject(program, "programs");

	return (
		<PageLayout className='programa-page'>
			<Banner />
			<ContentFluid>
				<section className="programa-section space-top">
					<PlaceAndDays />
					<TextType header1 extraBold>{getTranlation("section1_header")}</TextType>

					{programs.map((item, index) => (
						<Group item={item} key={`group0-${index}`} registrationLabel={getTranlation("registration")} />
					))}
				</section>
			</ContentFluid>
		</PageLayout >
	)
}

export default withLanguage(ProgramaPage)