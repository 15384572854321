
import React from 'react'
import './TextType.css'

const text = 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quibusdam sunt eligendi fugiat excepturi enim necessitatibus, aperiam harum odit fuga reiciendis unde dolores quisquam debitis accusantium quo earum nulla? Quam, veritatis! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quibusdam sunt eligendi fugiat excepturi enim necessitatibus, aperiam harum odit fuga reiciendis unde dolores quisquam debitis accusantium quo earum nulla? Quam, veritatis! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quibusdam sunt eligendi fugiat excepturi enim necessitatibus, aperiam harum odit fuga reiciendis unde dolores quisquam debitis accusantium quo earum nulla? Quam, veritatis!'


const TextType = ({ className, children = text, upperCase = false, header2 = false, header1 = false, bold = false, extraBold = false, secondType = false, textAlign = 'justify' }) => {

	const classes = ["text-type"];
	classes.push(secondType ? 'caveat' : '')

	if(textAlign === "center") {
		classes.push('text-center');
	}

	if(textAlign === "right") {
		classes.push('text-right');
	}

	if (!header2 && !header1 && textAlign === "justify") {
		classes.push('justify');
	}
	if (header1) {
		classes.push('header1');
	} else if (header2) {
		classes.push('header2');
	}

	if (extraBold) {
		classes.push('extraBold');
	} else if (bold) {
		classes.push('bold');
	}
	if (className) {
		classes.push(className);
	}
	if (upperCase) {
		classes.push("uppercase");
	}

	return (
		<p className={classes.join(' ')}>{children}</p>
	)
}

export default TextType