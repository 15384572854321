import React from 'react';
import './List.css';

const List = ({ options = [] }) => {
	return (
		<ul className='list-wraper'>
			{options.map(opt => (<li>{opt}</li>))}
		</ul>
	)
}

export default List