import React from 'react'
import './Banner.css'

const Banner = ({ imgSrc = "/Images/Home/IMG_banner_home.png", fullHeight = false, children, heightAuto = false, gradient = false }) => {


	return (
		<section className={`banner-container ${heightAuto ? 'auto' : ''} ${fullHeight ? 'fullHeight' : ''}`} style={
			{
				background: `${gradient ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)),' : ''} url('${imgSrc}') center / cover no-repeat`
			}
		}>
			{children}
		</section>
	)
}

export default Banner