import React from 'react'
import {
	ContentFluid,
	CardImageContainer,
	Banner,
	PageLayout,
	CardText,
	TextType
} from '../../../Components';
import { withLanguage } from '../../../Context';
import './RegiaoPage.css'

const RegiaoPage = ({ languageContext }) => {
	const { getValue, languagesConfigs } = languageContext;
	const getTranlation = (key) => {
		const { regiao } = languagesConfigs;
		return getValue(regiao, key);
	}
	return (
		<PageLayout className='regiao-page'>
			<Banner imgSrc="/Images/Regiao/Banner_Regiao.png" />

			<section className="space-top">
				<ContentFluid>
					<TextType header1>{getTranlation("section1_card_header_1")}</TextType>
					<div className='space-top10'>
						<TextType>{getTranlation("section1_card_description_1")}</TextType>
					</div>
					<div className='space-top10'>
						<TextType>{getTranlation("section1_card_description_2")}</TextType>
					</div>
					<div className='space-top10'>
						<TextType>{getTranlation("section1_card_description_3")}</TextType>
					</div>
					<div className='space-top10'>
						<TextType>{getTranlation("section1_card_description_4")}</TextType>
					</div>
				</ContentFluid>
			</section>
			<ContentFluid>
				<section className="main-content space-top">
					<div className=' space-top10'>
						<CardImageContainer cards={[
							{
								imgSrc: '/Images/Regiao/Regiao_DESTAQUE_1.png'
							},
							{
								imgSrc: '/Images/Regiao/Regiao_DESTAQUE_2.png'
							},
							{
								imgSrc: '/Images/Regiao/Regiao_DESTAQUE_3.png'
							},
							{
								imgSrc: '/Images/Regiao/Regiao_DESTAQUE_4.png'
							}
						]}/>
					</div>
				</section>
			</ContentFluid>

		</PageLayout>
	)
}

export default withLanguage(RegiaoPage);