import React from 'react'
import { ContentFluid, CardImageContainer, Banner, PageLayout, SectionDivider, List, TextType, PlaceAndDays } from '../../../Components'
import { withLanguage } from '../../../Context';
import './WhatIsItPage.css'

const WhatIsItPage = ({ languageContext }) => {
	const { getValue, getObject, getValueFormVar, languagesConfigs } = languageContext;
	const { o_que_e } = languagesConfigs;
	const getTranlation = (key) => {
		return getValue(o_que_e, key);
	}


	return (
		<PageLayout className='whatIsIt-page'>
			<Banner imgSrc="/Images/WhatIsIt/Banner.png" />
			<ContentFluid>

				<section className="main-content">
					<TextType header1>{getTranlation("section1_header")}</TextType>
					<SectionDivider
						leftComponent={
							<img className='img' alt='logo' src='/Images/Logotipo_cores.png' />
						}
						rightComponent={
							<>
								<div className='space-top10'>
									<TextType secondType header2>{getTranlation("section1_subheader1")}</TextType>
									<TextType>{getTranlation("section1_decription_1")}</TextType>
								</div>
								<div className='space-top10'>
									<TextType secondType header2>{getTranlation("section1_subheader2")}</TextType>
									<TextType>{getTranlation("section1_decription_2")}</TextType>
								</div>
								<div className='space-top10'>
									<TextType secondType header2>{getTranlation("section1_subheader3")}</TextType>
									<TextType>{getTranlation("section1_decription_3")}</TextType>
								</div>
								<div className='space-top10'>
									<TextType secondType header2>{getTranlation("section1_subheader4")}</TextType>
									<TextType>{getTranlation("section1_decription_4")}</TextType>
									<List options={getObject(o_que_e, 'section1_decription_4_points').map(point => (
										getValueFormVar(point)
									))} />
								</div>


							</>
						}
					/>
					<div className='space-top20'>
						<TextType>{getTranlation("section1_decription_5")}</TextType>
					</div>

				</section>
				<PlaceAndDays />
			</ContentFluid>
			<section className="space-top">
				<ContentFluid>
					<CardImageContainer cards={[
						{
							imgSrc: '/Images/WhatIsIt/destaque_1.png'
						},
						{
							imgSrc: '/Images/WhatIsIt/destaque_2.png'
						},
						{
							imgSrc: '/Images/WhatIsIt/destaque_3.png'
						},
						{
							imgSrc: '/Images/WhatIsIt/destaque_4.png'
						}
					]} />
				</ContentFluid>
			</section>

		</PageLayout >
	)
}

export default withLanguage(WhatIsItPage);