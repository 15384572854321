import React from 'react'


const ICONS_TYPES_ENUM = {
	wifi: ({ size }) => <i style={{ fontSize: size, color: '#25AACC' }} className="icon fas fa-wifi"></i>,
	washing_magine: ({ size }) => <i style={{ fontSize: size, color: '#25AACC' }} className="icon fas fa-wifi"></i>,
	coffee_maker: ({ size }) => <i style={{ fontSize: size, color: '#25AACC' }} className="icon fas fa-wifi"></i>,
	heater: ({ size }) => <i style={{ fontSize: size, color: '#25AACC' }} className="icon fas fa-wifi"></i>,
	iron: ({ size }) => <i style={{ fontSize: size, color: '#25AACC' }} className="icon fas fa-wifi"></i>,
	tv: ({ size }) => <i style={{ fontSize: size, color: '#25AACC' }} className="icon fas fa-wifi"></i>,
	hair_dryer: ({ size }) => <i style={{ fontSize: size, color: '#25AACC' }} className="icon fas fa-wifi"></i>,
	towels: ({ size }) => <i style={{ fontSize: size, color: '#25AACC' }} className="icon fas fa-wifi"></i>,
	bars: ({ size }) => <i style={{ fontSize: size, color: '#fff' }} className="icon fas fa-bars"></i>,
	close: ({ size }) => <i style={{ fontSize: size }}  className="icon fas fa-times"></i>,
	airport: ({ size }) => <i style={{ fontSize: size }} className="icon fas fa-plane-departure"></i>,
	bus: ({ size }) => <i style={{ fontSize: size }} className="icon fas fa-bus"></i>,
	train: ({ size }) => <i style={{ fontSize: size }} className="icon fas fa-train"></i>,
	car: ({ size }) => <i style={{ fontSize: size }} className="icon fas fa-car"></i>,
	expand: ({ size }) => <i style={{ fontSize: size }} className="icon fas fa-chevron-down"></i>,
	collapse: ({ size }) => <i style={{ fontSize: size }} className="icon fas fa-chevron-up"></i>,
	instagram: ({ size }) => <i style={{ fontSize: size }} className="icon fab fa-instagram"></i>,
	facebook: ({ size }) => <i style={{ fontSize: size }} className="icon fab fa-facebook-square"></i>,
}

const Icons = ({ type, size = "35px" }) => {

	const Icon = ICONS_TYPES_ENUM[type];
	return (
		<Icon size={size} />
	)
}

export default Icons

