import React from 'react'
import {
	ContentFluid,
	PageLayout,
	TextType,
	CardImageContainer
} from '../../Components';
import { withLanguage } from '../../Context';
import './ForAllPage.css'

const ForAllPage = ({ languageContext }) => {
	const { getValue, languagesConfigs } = languageContext;
	const getTranlation = (key) => {
		const { forall } = languagesConfigs;
		return getValue(forall, key);
	}
	return (
		<PageLayout className='forall-page'>
			<section className='section-1'>
				<div className='text-wrapper'>
					<ContentFluid>
						<div className='text-content'>
							<TextType className="main-header" header1>{getTranlation("header")}</TextType>
							<TextType className="text-description space-top30">{getTranlation("description")}</TextType>
							<div className='text-prizes_container space-top10'>
								<div className='text-prizes'>
									<div className='description space-top30'>
										<TextType className="places">{getTranlation("dia")}:</TextType><TextType className="places" secondType>{getTranlation("dia_desc")}</TextType>
									</div>
									<div className='description'>
										<TextType className="places">{getTranlation("hour")}:</TextType><TextType className="places" secondType>{getTranlation("hour_desc")}</TextType>
									</div>
									<div className='description'>
										<TextType className="places">{getTranlation("location")}:</TextType><TextType className="places" secondType textAlign='left'>{getTranlation("location_desc")}</TextType>
									</div>
								</div>
								<div className='banner_samll' >
									<img src="/Images/4All/banner.png" />
								</div>
							</div>

						</div>
					</ContentFluid>

				</div>
				<div className='banner' >
					<img alt='banner_image' src="/Images/4All/banner.png" />
				</div>
			</section>
			<section className="parceiros-page_images space-top">
				<ContentFluid>

					<div className='space-top20'>
						<CardImageContainer cards={[
							{ imgSrc: "/Images/4All/img_destaque1.png" },
							{ imgSrc: "/Images/4All/img_destaque2.png" },
							{ imgSrc: "/Images/4All/img_destaque3.png" },
							{ imgSrc: "/Images/Home/img_home_destaque1_C.png" },
						]} />
					</div>
				</ContentFluid>
			</section>



		</PageLayout>
	)
}

export default withLanguage(ForAllPage);