import './ContentFluid.css'

const ContentFluid = ({ children }) => {
	return (
		<div className='content-fluid-container'>
			<div className='content-fluid'>{children}</div>
		</div>
	)
}

export default ContentFluid