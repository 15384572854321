import React, { useEffect, useState } from 'react'
import {
	ContentFluid,
	PageLayout,
	TextType,
	Link,
	Banner,
	CardImageContainer,
	List
} from '../../Components';
import { withLanguage } from '../../Context';
import './AlojamentoPage.css'

const AlojamentoPage = ({ languageContext }) => {

	const { getValue, languagesConfigs, getObject, getValueFormVar, } = languageContext;
	// const [alojamentos, setAlojamentos] = useState([]);
	const { alojamento } = languagesConfigs;

	// useEffect(() => {
	// 	fetch('/Pages/Alojamentos.json').then((res) => res.json()).then(res => {
	// 		setAlojamentos(res);

	// 	})
	// }, []);

	const getTranlation = (key) => {
		return getValue(alojamento, key);
	}

	return (
		<PageLayout className='alojamento-page'>
			<Banner />

			<section className="space-top">
				<ContentFluid>
					<TextType header1 secondType>{getTranlation("section1_header1")}</TextType>
					<div className='space-top10'>
						<TextType>{getTranlation("section1_description1")}</TextType>
					</div>
					{
						getObject(alojamento, 'section_places').map(place => (
							<div className='space-top10'>
								<TextType header2>{getValueFormVar(place)}</TextType>
								<List options={getObject(place, 'links').map(linkObj => (
									<Link target="_blank" to={linkObj.link} external rel="noreferrer">{getValueFormVar(linkObj)}</Link>
								))} />
							</div>
						))
					}
				</ContentFluid>
			</section>
			<ContentFluid>
				<section className="main-content space-top">
					<div className=' space-top10'>
						<CardImageContainer />
					</div>
				</section>
			</ContentFluid>
		</PageLayout>
	)
}

export default withLanguage(AlojamentoPage);