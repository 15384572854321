import './CardIcon.css';
import Icons from '../Icons/Icons'

const CardIcon = ({ imgSrc, icon, text, width }) => {
	return (
		<div className='card-icon_container'>
			<div style={{ width:width || 'auto', textAlign: 'center'}}>
				{imgSrc && <img src={imgSrc} />}
				{icon && <Icons type={icon} size='60px' />}
			</div>
			<div className='card_icon_text'>{text}</div>
		</div>
	)
}

export default CardIcon