import React from 'react'
import { PageLayout, TextType } from '../../Components'
import { withLanguage } from '../../Context'
import './AvailableSoon.css'

const AvailableSoonPage = ({ languageContext }) => {

	// const { getValue, languagesConfigs } = languageContext;
	// // const [alojamentos, setAlojamentos] = useState([]);
	// const { common } = languagesConfigs;


	return (
		<PageLayout className='available-soon-container' noFooter>
			<img src="/Images/AvailableSoon/organização 1.png" />
			
		</PageLayout>

	)
}

export default withLanguage(AvailableSoonPage)