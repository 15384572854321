import React from 'react'
import { ContentFluid, Banner, PageLayout, SectionDivider, TextType, TranportInfo } from '../../../Components'
import { withLanguage } from '../../../Context';
import './TransportePage.css'

const TransportePage = ({ languageContext }) => {
	const { getValue, languagesConfigs } = languageContext;
	const getTranlation = (key) => {
		const { transporte } = languagesConfigs;
		return getValue(transporte, key);
	}
	return (
		<PageLayout className='transporte-page'>
			<Banner />
			<ContentFluid>
				<section className="main-content">
					<SectionDivider
						leftComponent={
							<img className='img' alt='logo' src='/Images/Transport/Mapa_Norte_portugal.png' />
						}
						rightComponent={
							<div className='oraganizacao-content'>
								<TextType header2 secondType>{getTranlation("section1_header")}</TextType>
								<TextType>{getTranlation("section1_description1")}</TextType>
								<TranportInfo />
							</div>
						}
					/>
				</section>
			</ContentFluid>
		</PageLayout>
	)
}

export default withLanguage(TransportePage);