import React from 'react'
import TextType from '../Text/TextType/TextType';
import { withLanguage } from '../../Context';
import './PlaceAndDays.css';

const PlaceAndDays = ({ languageContext }) => {
	const { getValue, languagesConfigs } = languageContext;
	const { common } = languagesConfigs;
	const getTranlation = (key) => {
		return getValue(common, key);
	}
	return (
		<section className="place_dates_wraper">
			<TextType header1 textAlign='center'>{getTranlation("local_emphasis")}</TextType>
			<TextType secondType header2 textAlign='center'>{getTranlation("local_description")}</TextType>
			<TextType secondType header2 textAlign='center'>{getTranlation("date_description")}</TextType>
		</section>
	)
}

export default withLanguage(PlaceAndDays)