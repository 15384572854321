import React from 'react'
import {
	ContentFluid,
	PageLayout,
	TextType,
	CardImageContainer
} from '../../Components';
import { withLanguage } from '../../Context';
import './EscolasPage.css'

const EscolasPage = ({ languageContext }) => {
	const { getValue, languagesConfigs, getSelectedLanguagues } = languageContext;
	const getTranlation = (key) => {
		const { escolas } = languagesConfigs;
		return getValue(escolas, key);
	}

	const imgInscriptionInfo = getSelectedLanguagues() === "pt" ? {
		src: "/Images/inscricao_pt.png",
		alt: "Link inscrição"
	}
		: {
			src: "/Images/inscricao_en.png",
			alt: "Link inscription"
		};

	return (
		<PageLayout className='escolas-page'>
			<section className='section-1'>
				<div className='text-wrapper'>
					<ContentFluid>
						<div className='text-content'>
							<TextType className="main-header" header1>{getTranlation("header")}</TextType>
							<TextType className="main-header" header2 secondType>{getTranlation("sub-header")}</TextType>
							<TextType className="text-description space-top30">{getTranlation("description")}</TextType>
							<div className='btn-info space-top20'>
								{/* <a target="_blank" href='#' rel="noreferrer"> */}
									<img alt={imgInscriptionInfo.alt} src={imgInscriptionInfo.src} />
								{/* </a> */}
							</div>
						</div>

					</ContentFluid>

				</div>
				<div className='banner' >
					<img alt='banner_image' src="/Images/Escolas/banner.png" />
				</div>
			</section>
			<section className="parceiros-page_images space-top">
				<ContentFluid>

					<div className='space-top20'>
						<CardImageContainer cards={[
							{ imgSrc: "/Images/Home/img_home_destaque1_A.png" },
							{ imgSrc: "/Images/WhatIsIt/destaque_4.png" },
							{ imgSrc: "/Images/Competicao/img_destaque3.png" },
							{ imgSrc: "/Images/4All/img_destaque4.png" },
						]} />
					</div>
				</ContentFluid>
			</section>

		</PageLayout>
	)
}

export default withLanguage(EscolasPage);